// @flow

import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
// import * as Scroll from 'react-scroll';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { v4 as uuidv4 } from 'uuid';
import { Modal } from 'react-responsive-modal';

// $FlowFixMe
import 'react-responsive-modal/styles.css';

// import Button from '../../components/Button';
import SourceHttp from './Source-http';
import SourceAPI from './Source-api';
import Section from '../../components/Section';
import Button from '../../components/Button';
import styles from './wizard.module.scss';

// $FlowFixMe
import metaValues from '../../../config';
import translation from '../../translate';

const data = [
  // {
  //   id: 1,
  //   title: 'Stream JSON',
  //   description: 'Load data from JSON file'
  // },
  {
    id: 2,
    title: 'Stream XML',
    description: 'Load data from XML file'
  },
  {
    id: 3,
    title: 'XML->JSON',
    description: 'Convert XML to JSON'
  }
];

const WizardScreen = (): any => {
  const { defaultTitle } = metaValues;
  const { language } = useSelector(({ common }: any) => common);
  const title = `${translation('_SEO_TITLE1', language)} - ${defaultTitle}`;

  const components = [
    {
      title: translation('_WIZARD_TITLE1', language),
      items: [
        {
          id: 11,
          title: 'API',
          description: 'Load data from API source',
          component: SourceAPI
        },
        {
          id: 12,
          title: 'HTTP',
          description: 'Load data from HTTP source',
          component: SourceHttp
        },
        {
          id: 13,
          title: 'FTP',
          description: 'Load data from FTP source',
          component: SourceAPI
        }
      ]
    },
    {
      title: translation('_WIZARD_TITLE2', language),
      items: [
        {
          id: 21,
          title: 'Mapper'
        },
        {
          id: 22,
          title: 'Merge'
        }
      ]
    },
    {
      title: translation('_WIZARD_TITLE3', language),
      items: [
        {
          id: 31,
          title: 'Mutate'
        }
      ]
    }
  ];

  const columnsFromBackend = {
    [uuidv4()]: {
      title: translation('_WIZARD_TITLE1', language),
      items: [components[0].items[0]]
    },
    [uuidv4()]: {
      title: translation('_WIZARD_TITLE4', language),
      items: data
    },
    [uuidv4()]: {
      title: translation('_WIZARD_TITLE5', language),
      items: [
        {
          id: 101,
          title: 'Mapper #1'
        },
        {
          id: 102,
          title: 'Mapper #2'
        }
      ]
    },
    [uuidv4()]: {
      title: translation('_WIZARD_TITLE3', language),
      items: []
    },
    [uuidv4()]: {
      title: translation('_WIZARD_TITLE6', language),
      items: []
    },
    [uuidv4()]: {
      title: translation('_WIZARD_TITLE7', language),
      items: [
        {
          id: 1001,
          title: 'Save to JSON'
        }
      ]
    }
  };

  const [open, setOpen] = useState(null);

  const onOpenModal = ({ item, columnId }: any) => {
    console.log(item);
    // $FlowFixMe
    setOpen({ item, columnId });
  };

  const onCloseModal = () => {
    setOpen(null);
  };

  const onDragEnd = (result: any, columns: any, setColumns: any) => {
    if (!result.destination) {
      return;
    }

    const { source, destination } = result;

    console.log({ source, destination });

    if (source.droppableId.includes('components')) {
      const [, section] = source.droppableId.split('-');
      const destColumn = columns[destination.droppableId];
      const itemSearch = components.find(x => x.title === section);
      const sourceItem =
        itemSearch && itemSearch.items ? itemSearch.items[source.index] : null;

      const destItems = [
        ...destColumn.items.slice(0, destination.index),
        sourceItem,
        ...destColumn.items.slice(destination.index)
      ].filter(x => !!x);

      const data = {
        ...columns,
        [destination.droppableId]: {
          ...destColumn,
          items: destItems
        }
      };

      return setColumns(data);
    }

    // different column
    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.items];
      const destItems = [...destColumn.items];
      const [removed] = sourceItems.splice(source.index, 1);

      destItems.splice(destination.index, 0, removed);

      const data = {
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems.filter(x => x.index !== destination.index)
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems
        }
      };

      return setColumns(data);
    }

    // same column
    const column = columns[source.droppableId];
    const copiedItems = [...column.items];
    const [removed] = copiedItems.splice(source.index, 1);

    copiedItems.splice(destination.index, 0, removed);

    return setColumns({
      ...columns,
      [source.droppableId]: {
        ...column,
        items: copiedItems
      }
    });
  };

  const [columns, setColumns] = useState(columnsFromBackend);
  const [cl, setCl] = useState(Object.keys(columns).length);
  const gridTemplateColumns = `repeat(${cl}, 1fr)`;

  const handleDelete = (data: any) => {
    const { columnId, id } = data;

    const newColumns = Object.keys(columns).reduce((acc: any, key: any) => {
      const columnData = columns[key];

      if (columnId === key) {
        const newItems = columnData.items.filter((item: any) => item.id !== id);
        const newColumn = { ...columnData, items: newItems };
        return { ...acc, [key]: newColumn };
      }

      return { ...acc, [key]: columnData };
    }, {});

    setColumns(newColumns);
  };

  const handleDeleteColumn = (columnId: any) => {
    // console.log(columns);
    const newColumns = Object.keys(columns).reduce((acc: any, key: any) => {
      if (columnId !== key) {
        return { ...acc, [key]: columns[key] };
      }

      return acc;
    }, {});
    // console.log(newColumns);
    setColumns(newColumns);
    setCl(Object.keys(newColumns).length);
  };

  const renderItem = ({ item, columnId }: any) => {
    return (
      <div className={styles.dragItem}>
        <span onClick={() => handleDelete({ ...item, columnId })}>
          <svg width="16" height="16" viewBox="0 0 36 36">
            <path d="M28.5 9.62L26.38 7.5 18 15.88 9.62 7.5 7.5 9.62 15.88 18 7.5 26.38l2.12 2.12L18 20.12l8.38 8.38 2.12-2.12L20.12 18z"></path>
          </svg>
        </span>
        <h5>{item.title}</h5>
        {item.description && <p>{item.description}</p>}
        <Button size="small" onClick={() => onOpenModal({ item, columnId })}>
          {translation('_WIZARD_CONFIGURE', language)}
        </Button>
      </div>
    );
  };

  const modalClassNames = {
    modal: styles.modalStyle,
    closeIcon: styles.closeIcon,
    closeButton: styles.closeButton
  };

  return (
    <section>
      <Helmet>
        <title>Build Wizard</title>
        <meta
          name="description"
          content={translation('_SEO_TEXT', language).join(' ')}
        />
        <meta
          property="keywords"
          content={translation('_SEO_KEYWORDS', language)}
        />
        <meta property="og:title" content={title} />
        <meta
          property="og:image"
          content={`${metaValues.domain}/public/data.svg`}
        />
      </Helmet>

      <Modal
        open={open}
        onClose={onCloseModal}
        classNames={modalClassNames}
        center
      >
        {open && !open.item.component && (
          <div>
            <h2>{open.item.title}</h2>
            <p>Some configuration options for the selected pipeline brick</p>
            <p>{open.item.description}</p>
            <p>{open.columnId}</p>
          </div>
        )}

        {open && open.item.component && (
          <div>
            <h2>{open.item.title}</h2>
            <open.item.component />
          </div>
        )}
      </Modal>

      <Section>
        <div className={styles.content}>
          <center>{translation('_WIZARD_TEXT', language)}</center>

          <DragDropContext
            onDragEnd={result => onDragEnd(result, columns, setColumns)}
          >
            <div className={styles.grid}>
              <center>
                <h2>{translation('_WIZARD_TITLE', language)}</h2>
              </center>

              <div className={styles.boxGray}>
                {components.map((item: any, index: number) => (
                  <Droppable
                    key={`components-${item.title}`}
                    droppableId={`components-${item.title}`}
                  >
                    {provided => (
                      <div className={styles.components}>
                        <h4>{item.title}</h4>
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          <div key={`${item.title}-${index}`}>
                            {item.items.map((item: any, index: number) => (
                              <Draggable
                                key={`components-${item.title}`}
                                draggableId={`components-${item.id}`}
                                index={index}
                              >
                                {provided => (
                                  <div
                                    className={styles.block}
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    {item.title}
                                  </div>
                                )}
                              </Draggable>
                            ))}
                          </div>
                          {provided.placeholder}
                        </div>
                      </div>
                    )}
                  </Droppable>
                ))}
              </div>

              <ul style={{ gridTemplateColumns }}>
                {Object.keys(columns).map(key => {
                  const columnId = key;
                  const { title, items } = columns[key];

                  return (
                    <Droppable key={columnId} droppableId={columnId}>
                      {provided => (
                        <li
                          key={columnId}
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          <span
                            className={styles.removeColumn}
                            onClick={() => {
                              handleDeleteColumn(columnId);
                            }}
                          >
                            <svg width="16" height="16" viewBox="0 0 36 36">
                              <path d="M28.5 9.62L26.38 7.5 18 15.88 9.62 7.5 7.5 9.62 15.88 18 7.5 26.38l2.12 2.12L18 20.12l8.38 8.38 2.12-2.12L20.12 18z"></path>
                            </svg>
                          </span>
                          <h3>{title}</h3>
                          <ol>
                            {items.map((item: any, index: number) => (
                              <Draggable
                                key={`${columnId}-${item.id}`}
                                draggableId={`${columnId}-${item.id}`}
                                index={index}
                              >
                                {provided => (
                                  <li
                                    className={styles.item}
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    {renderItem({ item, columnId })}
                                  </li>
                                )}
                              </Draggable>
                            ))}
                          </ol>
                        </li>
                      )}
                    </Droppable>
                  );
                })}
              </ul>
            </div>
          </DragDropContext>

          <center>
            <Button
              onClick={() => {
                const newColumns = {
                  ...columns,
                  [uuidv4()]: {
                    title: 'New column',
                    items: []
                  }
                };
                setColumns(newColumns);
                setCl(Object.keys(newColumns).length);
              }}
            >
              +
            </Button>

            <Button>{translation('_WIZARD_SAVE', language)}</Button>
          </center>
        </div>
      </Section>
    </section>
  );
};

export default WizardScreen;
