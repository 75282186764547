import Home from '../screens/Home';
import Wizard from '../screens/Wizard';
import Page404 from '../screens/Page404';

// ADMIN AREA

const Routes = [
  {
    path: '/:language?',
    element: Home,
    errorElement: Page404
  },
  {
    path: '/:language?/wizard',
    element: Wizard,
    errorElement: Page404
  },
  {
    path: '/404page/',
    element: Page404,
    exact: true
  },
  {
    exact: false,
    path: '*',
    element: Page404
  }
];

export default Routes;
