// @flow

import React from 'react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
// import * as Scroll from 'react-scroll';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import autoScroll from '../../components/Autoscroll';
// import Button from '../../components/Button';
import Section from '../../components/Section';
import Button from '../../components/Button';
import styles from './home.module.scss';

import Data from '../../assets/svg/data.svg';

// $FlowFixMe
import metaValues from '../../../config';
import translation from '../../translate';

const HomeScreen = (): any => {
  const { defaultTitle, allowedLanguages, defaultLanguage } = metaValues;
  const { language = defaultLanguage } = useSelector(
    ({ common }: any) => common
  );
  const title = `${translation('_SEO_TITLE1', language)} - ${defaultTitle}`;
  // const list = translation('_HOME_LIST', language);
  const navigate = useNavigate();

  useEffect(() => {
    if (!allowedLanguages.includes(language)) {
      return navigate('/404page');
    }
  }, [language]);

  return (
    <section>
      <Helmet>
        <title>{title}</title>
        <meta
          name="description"
          content={translation('_SEO_TEXT', language).join(' ')}
        />
        <meta
          property="keywords"
          content={translation('_SEO_KEYWORDS', language)}
        />
        <meta property="og:title" content={title} />
        <meta
          property="og:image"
          content={`${metaValues.domain}/public/data.svg`}
        />
      </Helmet>

      <Section>
        <div className={styles.content}>
          <div className={styles.top}>
            <h1>{translation('_SEO_TITLE1', language)}</h1>
            <h3 className={styles.promo1}>
              <div
                dangerouslySetInnerHTML={{
                  __html: translation('_SEO_TEXT', language).join('<br />')
                }}
              />
            </h3>
            <h2>{translation('_SEO_TITLE2', language)}</h2>
            <p>
              <div
                dangerouslySetInnerHTML={{
                  __html: `<p>${translation('_SEO_TEXT2', language).join('</p><p>')}</p>`
                }}
              />
            </p>
            <Button size="large">Book a demo</Button>
            <img src={Data} alt="Data Pipeline" />
          </div>
        </div>
        <Link to={`/${language}/wizard`} onClick={autoScroll}>
          Wizard
        </Link>
      </Section>
    </section>
  );
};

export default HomeScreen;
