const translate = {
  _HOME_CONTACT: 'Kontaktiere',
  _BOOK_DEMO: 'Buchen Sie eine Demo',
  _SEO_TITLE1:
    'Erstellen Sie in wenigen Minuten eine Online-EPG-INGESTION-PIPELINE-Datenverarbeitung',
  _SEO_TEXT: [
    'Sie können ganz einfach Ihre eigene EPG-/Datendatei erstellen',
    'aus mehreren Quellen <span>OHNE ENTWICKLER</span>',
    'und <span>ARCHITECTS</span> in Minuten.',
    'Alles was Sie brauchen – Quelle auswählen, Zuordnungen konfigurieren',
    'Mutationen und warten auf das Ergebnis.'
  ],
  _SEO_TITLE2: 'Was ist eine EPG-Ingestion-Pipeline?',
  _SEO_TEXT2: [
    'Eine EPG-Ingestion-Pipeline ist ein wesentliches Framework in der Datentechnik, das darauf ausgelegt ist, EPG-Daten aus vielen Quellen effizient in ein zentrales Speicher- oder Analysesystem zu importieren und zu verarbeiten. Dieser Prozess bildet das Rückgrat des Datenmanagements und wandelt Roh- und unterschiedliche Daten in umsetzbare Erkenntnisse/Elemente um.',
    'Die Pipeline umfasst typischerweise drei Schlüsselphasen: Extrahieren von Daten aus verschiedenen Quellen, Transformieren von Daten zur Anpassung an die Anforderungen des Zielsystems und Laden in ein Data Warehouse oder eine Cloud. Und der nächste Schritt kann der Export in ein beliebiges Dateiformat zur Verwendung in anderen Systemen sein.'
  ],
  _WIZARD_TITLE: 'Pipeline-Komponenten',
  _WIZARD_TEXT:
    'Um mit dem Aufbau Ihrer Pipeline zu beginnen, wählen Sie Elemente von oben aus und ziehen Sie sie in die Pipeline-Schritte unten.',
  _WIZARD_TITLE1: 'Quellen',
  _WIZARD_TITLE2: 'Zuordnungen',
  _WIZARD_TITLE3: 'Mutationen',
  _WIZARD_TITLE4: 'Vorbereiten',
  _WIZARD_TITLE5: 'Zuordnung',
  _WIZARD_TITLE6: 'Postops',
  _WIZARD_TITLE7: 'Final',
  _WIZARD_CONFIGURE: 'Konfigurieren',
  _WIZARD_SAVE: 'Speichern'
};

export default translate;
