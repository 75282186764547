const translate = {
  _HOME_CONTACT: `Зв'язатися`,
  _BOOK_DEMO: 'Забронюйте демо',
  _SEO_TITLE1:
    'Створіть онлайн-обробку даних EPG INGESTION PIPELINE за лічені хвилини',
  _SEO_TEXT: [
    'Ви можете легко створити свій власний EPG/файл даних',
    'з кількох джерел <span>БЕЗ РОЗРОБНИКІВ</span>',
    'і <span>АРХІТЕКТОРІВ</span> за хвилини.',
    'Все, що вам потрібно - вибрати джерело, налаштувати зіставлення',
    'мутації та чекати результату.'
  ],
  _SEO_TITLE2: 'Що таке конвеєр EPG?',
  _SEO_TEXT2: [
    'Конвеєр EPG Ingestion Pipeline — це важлива структура в розробці даних, призначена для ефективного імпорту та обробки даних EPG із багатьох джерел у централізовану систему зберігання чи аналізу. Цей процес утворює основу управління даними, перетворюючи необроблені та різні дані в практичні ідеї/елементи.',
    'Конвеєр зазвичай включає три ключові етапи: вилучення даних із різних джерел, перетворення даних відповідно до вимог цільової системи та завантаження їх у сховище даних або хмару. А наступним кроком може бути експорт у будь-який формат файлу для використання в інших системах.'
  ],
  _WIZARD_TITLE: 'Компоненти трубопроводу',
  _WIZARD_TEXT:
    'Щоб розпочати створення конвеєра, виберіть елементи зверху та перетягніть їх до кроків конвеєра нижче.',
  _WIZARD_TITLE1: 'Джерела',
  _WIZARD_TITLE2: 'Зіставлення',
  _WIZARD_TITLE3: 'Мутації',
  _WIZARD_TITLE4: 'Підготувати',
  _WIZARD_TITLE5: 'Відображення',
  _WIZARD_TITLE6: 'Постопи',
  _WIZARD_TITLE7: 'Остаточний',
  _WIZARD_CONFIGURE: 'Налаштувати',
  _WIZARD_SAVE: 'Зберегти'
};

export default translate;
