const translate = {
  _HOME_CONTACT: 'Contact',
  _BOOK_DEMO: 'Book a demo',
  _SEO_TITLE1:
    'Build online EPG INGESTION PIPELINE data processing in minutes!',
  _SEO_TEXT: [
    'You can easily build your own EPG/data file',
    'from couple of sources <span>WITHOUT DEVELOPERS</span>',
    'and <span>ARCHITECTS</span> in minutes.',
    'All you need - select source, configure mappings,',
    'mutations and wait for result.'
  ],
  _SEO_TITLE2: 'What Is a EPG Ingestion Pipeline?',
  _SEO_TEXT2: [
    'A EPG Ingestion Pipeline is an essential framework in data engineering designed to efficiently import and process EPG data from many sources into a centralized storage or analysis system. This process forms the backbone of data management, transforming raw and different data into actionable insights/items.',
    "The pipeline typically involves three key stages: extracting data from varied sources, transforming data to align with the target system's requirements, and loading it into a data warehouse or cloud. And next step can be export to any file format for usage in other systems."
  ],

  _WIZARD_TITLE: 'Pipeline components',
  _WIZARD_TEXT:
    'To start building your pipeline - pick items from top and drag to pipeline steps below.',
  _WIZARD_TITLE1: 'Sources',
  _WIZARD_TITLE2: 'Mappings',
  _WIZARD_TITLE3: 'Mutations',
  _WIZARD_TITLE4: 'Prepare',
  _WIZARD_TITLE5: 'Mapping',
  _WIZARD_TITLE6: 'Postops',
  _WIZARD_TITLE7: 'Final',
  _WIZARD_CONFIGURE: 'Configure',
  _WIZARD_SAVE: 'Save'
};

export default translate;
