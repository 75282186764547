// @flow
import { animateScroll as scroll } from 'react-scroll';

const scrollOption = {
  duration: 200,
  delay: 50,
  smooth: true
};

const autoScroll = (): any => scroll.scrollToTop(scrollOption);

export default autoScroll;
