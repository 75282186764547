// @flow
import * as React from 'react';
import { useState } from 'react';

import styles from '../sources.module.scss';
import Button from '../../../components/Button';
import Input from '../../../components/Input';

const SourceAPI = (props: any): any => {
  const { onSave } = props;
  const [value, setValue] = useState('');
  const [user, setUser] = useState('');
  const [password, setPassword] = useState('');

  return (
    <div className={styles.content}>
      <h4>Source API</h4>
      <p>Enter the URL of the API/FTP source you want to use.</p>
      <ul className={styles.grid}>
        <li>
          <div className={styles.title}>URL:</div>
          <div>
            <Input
              type="text"
              value={value}
              onChange={({ target }) => {
                setValue(target.value);
              }}
            />
          </div>
        </li>
        <li>
          <div className={styles.title}>User:</div>
          <div>
            <Input
              type="text"
              value={user}
              onChange={({ target }) => {
                setUser(target.value);
              }}
            />
          </div>
        </li>
        <li>
          <div className={styles.title}>Password:</div>
          <div>
            <Input
              type="text"
              value={password}
              onChange={({ target }) => {
                setPassword(target.value);
              }}
            />
          </div>
        </li>
      </ul>

      <Button
        onClick={() => {
          console.log('Save');

          if (onSave) {
            onSave({ url: value });
          }
        }}
      >
        Save
      </Button>
    </div>
  );
};

export default SourceAPI;
