// @flow
import * as React from 'react';
import { useState } from 'react';

import styles from '../sources.module.scss';
import Button from '../../../components/Button';
import Input from '../../../components/Input';

const SourceHttp = (props: any): any => {
  const { onSave } = props;
  const [value, setValue] = useState('');

  return (
    <div className={styles.content}>
      <h4>Source HTTP</h4>
      <p>Enter the URL of the HTTP/HTTPS source you want to use.</p>
      <ul className={styles.grid}>
        <li>
          <div className={styles.title}>HTTP/HTTPS url</div>
          <div>
            <Input
              type="text"
              value={value}
              onChange={({ target }) => {
                setValue(target.value);
              }}
            />
          </div>
        </li>
        <li>
          <div className={styles.title}>Headers</div>
          <div>
            <Input
              type="text"
              value={value}
              onChange={({ target }) => {
                setValue(target.value);
              }}
            />
          </div>
        </li>
      </ul>

      <Button
        onClick={() => {
          console.log('Save');

          if (onSave) {
            onSave({ url: value });
          }
        }}
      >
        Save
      </Button>
    </div>
  );
};

export default SourceHttp;
